import React, {useState} from "react";
import {Button, Tooltip} from "antd";
// import InvoiceSubmissionModal from "./InvoiceSubmissionModal";
import {useMercuryContext} from "../../user-context";
import {failedNotification} from "../../utils/notifications";

export default function RecalculateButton( { selectedRows }:any ) {

    const
        {gpSocket} = useMercuryContext(),
        rows = selectedRows || [],
        allowSubmit = rows.length && selectedRows.every((row: any) => row.status === 'INVOICED'),
        [isLoading, setLoading] = useState( false );

    const handleOk = () => {
        setLoading( true );
        const socket = gpSocket.adj;
        Promise.all( ( rows.map( ( row: any ) => {
            return new Promise( ( accept ) => {
                socket.emit( "recalculate", row._id, ( err:any ) => {
                    if ( err ) {
                        failedNotification( "Failed to recalculate batch batch", err );
                    }
                    return accept( null );
                })
            })
        })))
            .then( () => {
                console.log( "done")
                setLoading(false)
            })
    };

    return (
        <>
            <Tooltip
                title={!allowSubmit ? "Select at least 1 item" : ""}
                placement="bottom"
                color={"var(--tertiary-color)"}
                >
                <Button
                    onClick={handleOk}
                    loading={isLoading}
                    disabled={!allowSubmit}
                    type="primary">
                    Recalc
                </Button>
            </Tooltip>
        </>
)

}
