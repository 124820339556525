import React, {useState} from "react";
import {useMercuryContext} from "../../user-context";
import { Comment, Commenter as CommentInput } from "../../Components";
import LoadingStar from "../../ui/LoadingStar";

export default function Commenter({ id, placeholder, comments }) {
    const
        [ loading, setLoading ] = useState( false ),
        [ statedComments, setComments ] = useState( comments || [] ),
        { userDetails, client } = useMercuryContext();
    //
    // useEffect( () => {
    //     client.get(`/core-comment-v1/${id}`).then(({data}) => {
    //         setComments( data.sort( ( a, b ) => new Date( b.createdAt ) >= new Date( a.createdAt ) ) );
    //         setLoading(false);
    //     });
    // }, [ id, client, setComments, setLoading ])


    if ( loading ) {
        return <LoadingStar title={"Fetching project notes..."} />
    }

    return (<>
        <CommentInput
            id={id}
            onComment={async (newId, text, user ) => {
                await client.post( `/core-comment-v1`, {
                    document: id,
                    text,
                    user
                });
                await client.get(`/core-comment-v1/${id}`).then(({data}) => {
                    setComments( data.sort( ( a, b ) => new Date( b.createdAt ) >= new Date( a.createdAt ) ) );
                    setLoading(false);
                });
            }}
            placeholder={placeholder}
            user={userDetails._id}
        />
        {
            statedComments.map( ( comment ) => {
                const
                    c = {
                        key : comment._id,
                        ...comment,
                        setLike:  async ( commentId, user ) => {
                            await client.patch( `/core-comment-v1/like/${commentId}`, {
                                user
                            } );
                            await client.get(`/core-comment-v1/${id}`).then(({data}) => {
                                setComments( data.sort( ( a, b ) => new Date( b.createdAt ) >= new Date( a.createdAt ) ) );
                                setLoading(false);
                            });
                        },
                        currentUser: userDetails._id,
                        addReply: async ( commentId, text, user ) => {
                            await client.patch( `/core-comment-v1/reply/${commentId}`, {
                                text,
                                user
                            } );
                            await client.get(`/core-comment-v1/${id}`).then(({data}) => {
                                setComments( data.sort( ( a, b ) => new Date( b.createdAt ) >= new Date( a.createdAt ) ) );
                                setLoading(false);
                            });
                        }
                    }
                return <Comment { ...c } />
            })
        }
    </>)

}
